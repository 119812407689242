import React from "react"

const GithubContributionsGraph = () => {
  return (
    <div>
      <div class="github-link">
        <i class="fab fa-github-square"></i>
        <a href="https://github.com/rubicon44" target="_blank">rubicon44</a>
      </div>
      <a href="https://github.com/rubicon44" target="_blank">
        <img src="https://grass-graph.appspot.com/images/rubicon44.png?rotate=90" />
      </a>
    </div>
  )
}

export default GithubContributionsGraph