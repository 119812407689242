import React from "react";
import styled from "styled-components"
import { Timeline } from "react-twitter-widgets";

const TimelineCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TwitterTimeLine = () => {
  return (
    <TimelineCover>
      <Timeline
        dataSource={{ sourceType: "url", url: "https://twitter.com/rubiconbc44" }}
        options={{
          width: "375",
          height: "800",
          theme: "dark",
        }}
      />
    </TimelineCover>
  )
}

export default TwitterTimeLine